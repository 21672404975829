import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export const cn = (...inputs: ClassValue[]) => {
	return twMerge(clsx(inputs))
}

export const range = (start: number, stop: number, increment: number) => {
	if (increment <= 0) {
		throw new Error('Increment must be a positive number.')
	}

	const result = []
	for (let i = start; i < stop; i += increment) {
		result.push(i)
	}

	return result
}

const capitalize = (word: string) => {
	return word[0].toUpperCase() + word.slice(1).toLowerCase()
}

export const toCapitalCase = (str: string) => {
	if (str.includes(' ')) {
		return str
			.split(' ')
			.map((word) => {
				return capitalize(word)
			})
			.join(' ')
	}

	return capitalize(str)
}
